import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { CEMETERIES_LIST_DEFAULT_SORT_PARAM } from 'src/app/admin/chronicle-admin-organization-shared/cemeteries-list-sort-params.enum';
import { CemeteryService } from 'src/app/core/cemetery.service';
import { CemeteryInfo } from 'src/app/interfaces/cemetery-info';
import { Image } from '../../interfaces/image';
import { RegionalSettingsService } from 'src/app/core/regional-settings.service';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { MapActionsService } from 'src/app/core/map-actions.service';
import { SidebarService } from 'src/app/core/sidebar.service';

@Component({
  selector: 'cl-cemetery-list-public',
  templateUrl: './cemetery-list-public.component.html',
  styleUrls: ['./cemetery-list-public.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CemeteryListPublicComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  cemeteries = [];
  defaultSortParam = CEMETERIES_LIST_DEFAULT_SORT_PARAM;
  isChronicleAdmin: boolean;
  isLoading = false;
  shimmers = new Array(10);

  totalData = 0;
  totalPage = 0;
  dataPerPage = 20;
  currentPage = 1;
  org: any;

  regionalSortParams: Array<string>;

  get regionalSettingsForUser() {
    return this.regionalSettingsService.regionalSettingsForUser$.value;
  }

  constructor(
    private regionalSettingsService: RegionalSettingsService,
    private cemeteriesService: CemeteryService,
    private changeDetectionRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private routerUtilsService: RouterUtilsService,
    private mapActionsService: MapActionsService,
    private sidebarService: SidebarService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.org = params.org;
    });

    this.regionalSortParams = [
      'Creation date (newest first)',
      'Creation date (oldest first)',
      `${this.regionalSettingsForUser.cemetery} name (a - z)`,
      `${this.regionalSettingsForUser.cemetery} name (z - a)`,
      `${this.regionalSettingsForUser.cemetery} status (public first)`,
      `${this.regionalSettingsForUser.cemetery} status (private first)`,
    ];
  }

  ngOnInit(): void {
    this.loadDataCemeteries();

    this.cemeteriesService.updateCemeteryUniqueName(null);

    this.activatedRoute.queryParams
      .pipe(
        tap(params => {
          if (!params.org) {
            this.sidebarService.hideTogleButton();
            this.sidebarService.close();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.sidebarService.showTogleButton();
  }

  loadDataCemeteries() {
    this.changeDetectionRef.markForCheck();
    this.isLoading = true;
    this.cemeteriesService
      .getCemeteriesNew(this.currentPage, this.dataPerPage, this.org, this.defaultSortParam)
      .pipe(
        takeUntil(this.destroy$),
        tap(cemeteries => {
          this.isLoading = false;
          this.totalData = cemeteries.paginate.total;
          this.totalPage = Math.ceil(this.totalData / this.dataPerPage);
          if (cemeteries.data.length) {
            const filteredCemetery = cemeteries.data.filter(cemetery => cemetery.status !== 'Visible');
            if (this.cemeteries.length) {
              this.cemeteries = this.cemeteries.concat(filteredCemetery);
            } else {
              this.cemeteries = filteredCemetery;
            }
          }
          this.changeDetectionRef.markForCheck();
          this.cemeteriesService.setSortCemeteries(this.defaultSortParam);
        })
      )
      .subscribe();
  }

  navigateToCemetery(cemetery: CemeteryInfo) {
    if (cemetery.archived && !this.isChronicleAdmin) {
      return;
    }

    if (cemetery.archived) {
      const path = this.routerUtilsService.getPath(cemetery.unique_name, 'edit');
      this.router.navigate(path);
      return;
    }

    this.mapActionsService.showCemeteryWithAnimation(cemetery);
  }

  onScrollDown() {
    if (this.currentPage < this.totalPage) {
      this.currentPage += 1;
      this.loadDataCemeteries();
    }
  }

  onSortParamChange(sortParam) {
    this.cemeteries = [];
    this.currentPage = 1;
    this.defaultSortParam = sortParam;
    this.changeDetectionRef.markForCheck();
    this.loadDataCemeteries();
  }

  getImageSrc(image: Image) {
    return image ? image.url : './assets/images/search_cemetery_icon.svg';
  }

  isArchived(cemetery): boolean {
    return cemetery.archived;
  }
}
