import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FilterFormData } from '../interfaces/filter-form-data';
import { SearchPlotsParameters } from '../interfaces/search-plots-parameters';
import { SearchRecordsParameters } from '../interfaces/search-records-parameters';
import { Interment } from '../interfaces/interment';
import { HttpUtilsService } from './http-utils.service';
import { SearchPlotsRecordsParameters } from '../interfaces/search-plots-records-parameters';
import { tap } from 'rxjs/operators';
import { PlotDetails } from '../interfaces/plot-details';
import { ValidateResponseService } from '../server-response-validation/validate-response.service';
import { ValidatorType } from '../server-response-validation/validator-type.enum';
import { SectionData } from '../interfaces/section-data';
import { Plot } from '../interfaces/plot';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { PersonSearchItem } from '../interfaces/person-search-item';
import { SearchPersonsParameters } from '../interfaces/search-persons-parameters';
import { ChangePlotIdData } from '../interfaces/change-plot-id-data.interface';
import { PlotListItem } from '../interfaces/plot-list-item';
import * as moment from 'moment';
import { AdvanceSearchResult, FormAdvanceSearch } from '../interfaces/advance-search';

@Injectable({
  providedIn: 'root',
})
export class PlotsService {
  private readonly isNewDataPlot = new BehaviorSubject(false);
  private readonly isNeedHit = new ReplaySubject<boolean>(1);
  private readonly dataAdvanceSearchFilter = new BehaviorSubject<any>(null);
  private readonly dataBoundAdvanceSearch = new BehaviorSubject<any>(null);
  private readonly isLoad = new BehaviorSubject(false);
  private readonly dataAdvanceSearchResultObj = new BehaviorSubject<AdvanceSearchResult>(null);
  private readonly isToggleOn = new BehaviorSubject(false);
  public readonly currentActivePlot$ = new BehaviorSubject<PlotDetails>(null);

  constructor(
    private httpClient: HttpClient,
    private readonly httpUtils: HttpUtilsService,
    private readonly validateResponseService: ValidateResponseService
  ) {}

  getPlotsList(cemeteryIdentifier: string | number, options: FilterFormData = {}) {
    const url = this.httpUtils.getResourceUrl(['details', 'cemetery', cemeteryIdentifier, 'cemetery-data']);
    const params: Record<string, string> = {};

    if (options.age !== undefined) {
      params.age = options.age.join(',');
    }

    if (options.dod !== undefined) {
      params.dod = this.getPreparedDate(options.dod);
    }

    if (options.dob !== undefined) {
      params.dob = this.getPreparedDate(options.dob);
    }

    if (options.status !== undefined) {
      params.status = options.status.charAt(0).toUpperCase() + options.status.slice(1);
    }

    if (options.rs !== undefined) {
      params.rs = options.rs + '';
    }

    if (options.feature !== undefined) {
      params.feat_story = options.feature;
    }

    if (options.story !== undefined) {
      params.avail_story = options.story;
    }

    return this.httpClient
      .get(url, { params })
      .pipe(
        tap((sections: SectionData[]) => sections.forEach(section => this.validateResponseService.validate(ValidatorType.Section, section)))
      );
  }

  getSellPlotsList(cemeteryName: string, dataFilter: any) {
    const url = this.httpUtils.getResourceUrl(['details', 'cemetery', cemeteryName, 'cemetery-data']);
    const params: Record<string, string> = {};
    if (dataFilter && dataFilter !== undefined) {
      if (dataFilter.plot_type) {
        params.plot_type = dataFilter.plot_type;
      }
      if (dataFilter.price_range) {
        params.price_range = dataFilter.price_range;
      }
    }
    params.status = 'For Sale';

    // if (dataFilter) {
    //   params = {
    //     status: 'For Sale',
    //     plot_type: dataFilter.plot_type,
    //     price_range: dataFilter.price_range,
    //   };
    // } else {
    //   params = {
    //     status: 'For Sale',
    //   };
    // }

    return this.httpClient
      .get(url, { params })
      .pipe(
        tap((sections: SectionData[]) => sections.forEach(section => this.validateResponseService.validate(ValidatorType.Section, section)))
      );
  }

  getSellPlotsPrice(cemeteryName: string) {
    const url = this.httpUtils.getResourceUrl(['details', 'cemetery', cemeteryName, 'public-cemetery-data', 'price-range']);
    return this.httpClient.post(url, {});
  }

  getPlotsByViewPort(bounds: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'plots-in-viewport']);

    return this.httpClient.get(url.slice(0, -1), { params: { bounds } });
  }

  getPlotsByViewPortWithToken(bounds: string, token: string) {
    const url = this.httpUtils.getResourceUrl(['plots-in-viewport']);

    return this.httpClient.get(url, {
      params: { bounds },
      headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
    });
  }

  getPlotInfoWithLocation(cemeteryIdentifier: string | number, plotId: string) {
    const url = this.httpUtils.getResourceUrl(['details', 'cemetery', cemeteryIdentifier, 'plots', plotId]);

    return this.httpClient.get<PlotDetails>(url).pipe(
      tap(plotDetails => {
        this.validateResponseService.validate(ValidatorType.PlotDetails, plotDetails);
        this.currentActivePlot$.next(plotDetails);
      })
    );
  }

  getPlotInfoWithLocationWithToken(cemeteryIdentifier: string | number, plotId: string, token: string) {
    const url = this.httpUtils.getResourceUrl(['details', 'cemetery', cemeteryIdentifier, 'plots', plotId]);

    return this.httpClient
      .get<PlotDetails>(url, {
        headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
      })
      .pipe(tap(plotDetails => this.validateResponseService.validate(ValidatorType.PlotDetails, plotDetails)));
  }

  getUnknownLocationPlot(cemeteryUniqueName: string, options: FilterFormData = {}) {
    const url = this.httpUtils.getResourceUrl(['details', 'cemetery', cemeteryUniqueName, 'cemetery-data', 'unknown-location']);

    const params: Record<string, string> = {};

    if (options.age !== undefined) {
      params.age = options.age.join(',');
    }

    if (options.dod !== undefined) {
      params.dod = this.getPreparedDate(options.dod);
    }

    if (options.dob !== undefined) {
      params.dob = this.getPreparedDate(options.dob);
    }

    if (options.status !== undefined) {
      params.status = options.status.charAt(0).toUpperCase() + options.status.slice(1);
    }

    if (options.rs !== undefined) {
      params.rs = options.rs + '';
    }

    return this.httpClient.get<PlotListItem>(url, { params });
  }

  get isNewDataPlot$() {
    return this.isNewDataPlot.asObservable();
  }

  get isNeed$() {
    return this.isNeedHit.asObservable();
  }

  get dataAdvanceSearch$() {
    return this.dataAdvanceSearchFilter.asObservable();
  }

  get dataBoundsAdvanceSearch$() {
    return this.dataBoundAdvanceSearch.asObservable();
  }

  get isLoad$() {
    return this.isLoad.asObservable();
  }

  get dataAdvanceSearchResult() {
    return this.dataAdvanceSearchResultObj.asObservable();
  }

  get isToggleOn$() {
    return this.isToggleOn.asObservable();
  }

  addPlotSection(value: any) {
    const url = this.httpUtils.getResourceUrl(['adv_table', 'plots']);
    return this.httpClient.post<any>(url, value);
  }

  updateisLoad(newStatus: boolean) {
    return this.isLoad.next(newStatus);
  }

  updateisNeed(newStatus: boolean) {
    return this.isNeedHit.next(newStatus);
  }

  updateDataAdvanceSearch(data) {
    return this.dataAdvanceSearchFilter.next(data);
  }

  updateDataBoundsAdvanceSearch(data) {
    return this.dataBoundAdvanceSearch.next(data);
  }

  updateDataAdvanceSearchResult(data: AdvanceSearchResult) {
    this.dataAdvanceSearchResultObj.next(data);
  }

  updateIsToggleOn(newStatus: boolean) {
    return this.isToggleOn.next(newStatus);
  }

  searchPlotsWithRecords({ limit = 3, cemeteryIdentifier, search, cemeteries }: SearchPlotsRecordsParameters) {
    const url = this.httpUtils.getResourceUrl(['search', 'plots-records']);
    const params: Record<string, any> = {
      search,
      limit: limit.toString(),
    };

    if (cemeteryIdentifier !== undefined) {
      params.cem_unique_name = cemeteryIdentifier.toString();
    }

    if (cemeteries !== undefined) {
      params.user_cem_ids = cemeteries;
    }

    return this.httpClient.get(url, { params }).pipe(
      tap<{ records?; plots? }>(data => {
        if ('plots' in data) {
          data.plots.forEach(plot => this.validateResponseService.validate(ValidatorType.Plot, plot));
        }
        if ('records' in data) {
          data.records.forEach(interment => this.validateResponseService.validate(ValidatorType.Interment, interment));
        }
      })
    );
  }

  searchPlotsWithRecordsV2({
    limit = 3,
    cemeteryIdentifier,
    search,
    cemeteries,
    org,
    search_type,
    offset,
    dob,
    dod,
  }: SearchPlotsRecordsParameters) {
    const url = this.httpUtils.getResourceUrl(['search', 'plots-records-persons']);
    const params: Record<string, any> = {
      search,
      limit: limit.toString(),
    };

    if (offset !== undefined) {
      params.offset = offset;
    }

    if (org && cemeteryIdentifier === undefined) {
      params.org = org;
    }

    if (cemeteryIdentifier !== undefined) {
      params.cem_id = cemeteryIdentifier;
    }

    if (cemeteries !== undefined) {
      params.user_cem_ids = cemeteries;
    }

    if (search_type !== undefined) {
      params.search_type = search_type;
    }

    if (dod !== undefined) {
      params.dod = this.getPreparedDate(dod);
    }

    if (dob !== undefined) {
      params.dob = this.getPreparedDate(dob);
    }

    return this.httpClient.get(url, { params }).pipe(
      tap<{ persons?; plots? }>(data => {
        if ('plots' in data) {
          data.plots.forEach(plot => this.validateResponseService.validate(ValidatorType.Plot, plot));
        }
        if ('persons' in data) {
          data.persons.forEach(interment => interment);
        }
      })
    );
  }

  searchPlotsWithRecordsAdminV2({
    limit = 3,
    cemeteryIdentifier,
    search,
    cemeteries,
    org,
    search_type,
    offset,
    dob,
    dod,
  }: SearchPlotsRecordsParameters) {
    const url = this.httpUtils.getResourceUrl(['search', 'plots-records-persons']);
    const params: Record<string, any> = {
      search,
      limit: limit.toString(),
    };

    if (offset !== undefined) {
      params.offset = offset;
    }

    if (org && cemeteryIdentifier === undefined) {
      params.org_id = org;
    }

    if (cemeteryIdentifier !== undefined) {
      params.cem_id = cemeteryIdentifier.toString();
    }

    if (search_type !== undefined) {
      params.search_type = search_type;
    }

    if (cemeteries !== undefined) {
      params.user_cem_ids = cemeteries;
    }

    if (dod !== undefined) {
      params.dod = this.getPreparedDate(dod);
    }

    if (dob !== undefined) {
      params.dob = this.getPreparedDate(dob);
    }

    return this.httpClient.get(url, { params }).pipe(
      tap<{ persons?; plots? }>(data => {
        if ('plots' in data) {
          data.plots.forEach(plot => this.validateResponseService.validate(ValidatorType.Plot, plot));
        }
        if ('persons' in data) {
          data.persons.forEach(interment => interment);
        }
      })
    );
  }

  searchPlotsWithPersons({ limit = 3, cemeteryIdentifier, search, cemeteries }: SearchPlotsRecordsParameters) {
    const url = this.httpUtils.getResourceUrl(['search', 'plots-persons']);
    const params: Record<string, any> = {
      search,
      limit: limit.toString(),
    };

    if (cemeteryIdentifier !== undefined) {
      params.user_cem_ids = cemeteryIdentifier;
    }

    // if (cemeteries !== undefined) {
    //   params.user_cem_ids = cemeteries;
    // }

    if (cemeteries?.length !== undefined && cemeteries?.length > 1) {
      params.user_cem_ids = cemeteries.join(',');
    }

    return this.httpClient.get(url, { params }).pipe(
      tap<{ persons?; plots? }>(data => {
        if ('plots' in data) {
          data.plots.forEach(plot => this.validateResponseService.validate(ValidatorType.Plot, plot));
        }
        if ('persons' in data) {
          data.persons.forEach(interment => this.validateResponseService.validate(ValidatorType.Interment, interment));
        }
      })
    );
  }

  searchPlots(params: SearchPlotsParameters) {
    const url = this.httpUtils.getResourceUrl(['search', 'plots']);
    const parameters = Object.keys(params)
      .filter(key => params[key] !== null && params[key] !== undefined)
      .reduce((acc, key) => {
        acc[key] = params[key].toString();
        return acc;
      }, {});

    return this.httpClient
      .get(url, { params: parameters })
      .pipe(tap((plots: Plot[]) => plots.forEach(plot => this.validateResponseService.validate(ValidatorType.Plot, plot))));
  }

  searchRecords(options: SearchRecordsParameters) {
    const url = this.httpUtils.getResourceUrl(['search', 'records']);
    const params: Record<string, string> = {};

    if (options.age !== undefined) {
      params.age = options.age.join(',');
    }

    if (options.cem_unique_name !== undefined) {
      params.cem_unique_name = options.cem_unique_name;
    }

    if (options.cem_id !== undefined) {
      params.cem_id = options.cem_id.toString();
    }

    if (options.dod !== undefined) {
      params.dod = this.getPreparedDate(options.dod);
    }

    if (options.dob !== undefined) {
      params.dob = this.getPreparedDate(options.dob);
    }

    if (options.status !== undefined) {
      params.status = options.status.charAt(0).toUpperCase() + options.status.slice(1);
    }

    if (options.rs !== undefined) {
      params.rs = options.rs + '';
    }

    if (options.offset !== undefined) {
      params.offset = options.offset + '';
    }

    if (options.limit !== undefined) {
      params.limit = options.limit + '';
    }

    if (options.plotId !== undefined) {
      params.plotId = options.plotId;
    }

    if (options.search !== undefined) {
      params.search = options.search;
    }

    if (options.user_cem_ids !== undefined) {
      params.user_cem_ids = options.user_cem_ids.join(',');
    }

    return this.httpClient
      .get<Interment[]>(url, { params })
      .pipe(
        tap((interments: Interment[]) =>
          interments.forEach(interment => this.validateResponseService.validate(ValidatorType.Interment, interment))
        )
      );
  }

  searchPersons(params: SearchPersonsParameters = {}): Observable<PersonSearchItem[]> {
    const url = this.httpUtils.getResourceUrl(['search', 'persons']);

    const parameters = Object.entries(params)
      .filter(([key, value]) => Boolean(value))
      .reduce((acc, [key, value]) => {
        if (key === 'exclude') {
          return `${acc}&${value.map(item => `${key}=${item}`).join('&')}`;
        }

        if (key === 'dob') {
          return `${acc}&${key}=${this.getPreparedDate(value)}`;
        }

        if (key === 'dod') {
          return `${acc}&${key}=${this.getPreparedDate(value)}`;
        }

        return `${acc}&${key}=${value}`;
      }, '');

    return this.httpClient.get<PersonSearchItem[]>(`${url}?${parameters}`).pipe(
      tap(items => {
        items.forEach(item => {
          this.validateResponseService.validate(ValidatorType.Person, item);
        });
      })
    );
  }

  advanceSearchPublic(body: FormAdvanceSearch, currentPage, dataPerPage, bounds?: any) {
    let params;
    const data = {
      ...body,
      page: currentPage,
      limit: dataPerPage,
    };
    if (bounds) {
      params = {
        bounds,
      };
    }
    const url = this.httpUtils.getResourceUrl(['search', 'advanced-search-public']);
    return this.httpClient.post(url, data, { params });
  }

  getPlot(cemeteryId: string, plotId: number) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryId, 'plot', plotId]);

    return this.httpClient.get<Plot>(url).pipe(tap(plotDetails => this.validateResponseService.validate(ValidatorType.Plot, plotDetails)));
  }

  editPlot(editedPlot: any, cemeteryIdentifier: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryIdentifier, 'plot', editedPlot.id]);

    return this.httpClient.put(url, editedPlot);
  }

  changePlotId(cemeteryId: string, plotId: string, data: ChangePlotIdData) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryId, 'change_plot_id', plotId]);

    return this.httpClient.put<PlotDetails>(url, data);
  }

  getFeaturedStory(cemeteryName: string) {
    const url = this.httpUtils.getResourceUrl(['details', 'cemetery', cemeteryName, 'cemetery-data']);
    const params: Record<string, any> = {
      feat_story: 1,
    };
    // params.feat_story = 1;
    return this.httpClient.get<SectionData[]>(url, { params });
  }

  newDataPlot(status: boolean) {
    this.isNewDataPlot.next(status);
  }

  clearGeoJsonPlots(cemeteryName: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'plots-clear']);
    return this.httpClient.post<any>(url.slice(0, -1), {
      cemetery_unique_name: cemeteryName,
    });
  }

  createUnassignedPlotRoi(cemeteryIdentifier: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryIdentifier, 'unassigned_roi']);
    return this.httpClient.post(url, null);
  }

  assignPlot(cemeteryUniqueName: string, currentPlotId: string, newPlotId: string, keep: boolean) {
    const url = this.httpUtils.getResourceUrl(['assigned_plot']);
    return this.httpClient.put(url, { plot_id: newPlotId, current_plot_id: currentPlotId, cemetery_unique_name: cemeteryUniqueName, keep });
  }

  getPurchasePlotData(organizationId: number, type: 'Pre-need' | 'At-need'): Observable<any> {
    const url = this.httpUtils.getResourceUrl(['customform', 'public', organizationId]);
    const params = {
      // display_on: 'plot_purchase',
      sub_type: type,
    };
    return this.httpClient.get<any>(url, { params });
  }

  createPlotPurchase(plotId: number, value: any) {
    const url = this.httpUtils.getResourceUrl(['cemetery', plotId, 'request_table', 'public', 'plot_purchase']);
    return this.httpClient.post<any>(url, value);
  }

  createPlotPurchaseToRequest(plotId: number, value: any) {
    const url = this.httpUtils.getResourceUrl(['cemetery', plotId, 'request_table', 'public', 'plot_purchase']);
    return this.httpClient.post<any>(url, value);
  }

  private getPreparedDate(datesDataOutput: string[]) {
    const dates = datesDataOutput.filter(Boolean).map(date => moment(date).format('D-MM-Y'));
    const prepared = dates.length === 1 ? Array(2).fill(dates.pop()) : dates;
    return prepared.join(',');
  }
}
