import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import Swiper from 'swiper';
import { Image } from '../../interfaces/image';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DownloadImageService } from './download-image.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../confirmation-dialog/confirmation-dialog-data';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ImageUploaderService } from '../../upload/services/image-uploader.service';
import { EMPTY, Subject } from 'rxjs';
import { LoadingService } from '../../loading-overlay/loading.service';
import { SwiperModalData } from './swiper-modal-data';
import { AddIconsThroughMaterialService } from '../../core/add-icons-through-material.service';
import { SnackbarNotificationService } from '../../core/snackbar-notification.service';

@Component({
  selector: 'cl-swiper-modal',
  templateUrl: './swiper-modal.component.html',
  styleUrls: ['./swiper-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ImageUploaderService, DownloadImageService],
})

/**
 * Example of usage:
 *
 * in component, where you have loop through your images you should add listener click,
 * for example <img [src]="image.url" (click)="open(i)"/> (i) - index of current image
 *
 * Then you have to create open(index) method, for example
 *
 * open(index: number) {
 *   this.dialog.open<SwiperModalComponent, SwiperModalData, Image[]>(SwiperModalComponent, {
 *    data: {
 *       images: <images>,
 *       startFrom: index,
 *       entityTitle: <entity name>
 *     },
 *    panelClass: 'slider-container',
 *   });
 * }
 * where images - images list for your modal preview, startFrom - index of image for start preview,
 * entityTitle - title of cemetery or plot, which related to images (shown in modal header)
 *
 * Modal slider return an array of new images, which you can assign to your original images in
 * afterClosed() method, for example
 *
 * this.dialog.open(...)
 *  .afterClosed()
 *    .pipe(
 *      tap( (updatedImages) => this.images = updatedImages)
 *     )
 *  .subscribe()
 *
 */
export class SwiperModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private config: any = {
    init: false,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    navigation: {
      nextEl: '.arrow-right',
      prevEl: '.arrow-left',
    },
    initialSlide: this.data.startFrom,
    centeredSlides: true,
    updateOnImagesReady: true,
  };
  private swiper: Swiper;
  private destroy$ = new Subject<void>();

  get oneImageLeft() {
    return this.images ? this.images.length === 1 : false;
  }

  get assignAsMainAvailable() {
    return Boolean(this.data.assignAsMain);
  }

  get shareAvailable() {
    return Boolean(this.data.share);
  }

  get deleteAvailable() {
    return Boolean(this.data.delete);
  }

  get downloadAvailable() {
    return Boolean(this.data.download);
  }

  get isPublic() {
    return Boolean(this.data.public);
  }

  images: Image[];
  isChanged = false;
  startPosition: number;
  swiperReady = false;
  url = 'https://storage.googleapis.com/dev-chronicle/';
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private readonly dialogRef: MatDialogRef<SwiperModalComponent>,
    private readonly clipboard: Clipboard,
    private readonly downloadImageService: DownloadImageService,
    private readonly snackbar: MatSnackBar,
    private readonly imageService: ImageUploaderService,
    private readonly matDialog: MatDialog,
    private readonly loader: LoadingService,
    private readonly snackBarNotificationService: SnackbarNotificationService,
    @Inject(MAT_DIALOG_DATA) public data: SwiperModalData
  ) {
    this.addIconsThroughMaterialService.add([
      { name: 'close', src: '/assets/images/close_icon_white.svg' },
      { name: 'assign-as-main', src: '/assets/images/assign_image_icon.svg' },
      { name: 'share', src: '/assets/images/share_icon.svg' },
      { name: 'download', src: '/assets/images/download_icon_white.svg' },
      { name: 'delete', src: '/assets/images/delete_icon.svg' },
    ]);
  }

  ngOnInit(): void {
    this.images = this.data.images.slice();
    this.startPosition = this.data.startFrom;
    this.listenBackdropClick();
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    this.swiper = new Swiper('.swiper-container', this.config);
    this.swiper.on('imagesReady', () => {
      this.swiperReady = true;
      this.cdr.detectChanges();
    });

    this.swiper.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.swiper.destroy();
  }

  assignAsMain() {
    this.isChanged = true;
    const index = this.swiper.activeIndex;
    const mainImage = this.images.splice(index, 1);
    this.images.unshift(...mainImage);
    this.swiper.update();
    this.swiper.slideTo(0, 0, false);
    this.openSuccessMessage('Assigned!');
  }

  close() {
    this.dialogRef.close(this.isChanged ? this.images : this.isChanged);
  }

  copyLink() {
    const index = this.swiper.activeIndex;
    const url = this.images[index].url ? this.images[index].url : this.images[index].image;

    if (navigator.share === undefined) {
      this.clipboard.copy(url);
      this.openSuccessMessage('Image link copied to clipboard!');
    } else {
      navigator.share({ url });
    }
  }

  download() {
    const index = this.swiper.activeIndex;
    const url = this.images[index].url ? this.images[index].url : this.images[index].image;
    return this.downloadImageService.download(url);
  }

  delete() {
    const index = this.swiper.activeIndex;
    const id = this.images[index].id;

    this.matDialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
        width: '448px',
        autoFocus: false,
        data: {
          header: 'Delete image?',
          description: 'This image will not be stored on Chronicle anymore. Are you sure you want to delete this image?',
          actionButtonLabel: 'Delete',
          cancelButtonLabel: 'Cancel',
        },
      })
      .afterClosed()
      .pipe(
        switchMap(dialogResult => {
          this.loader.run();
          if (dialogResult) {
            if (this.data.public) {
              return this.imageService.removeFromPublic({ id }).pipe(finalize(() => this.loader.stop()));
            } else {
              return this.imageService.remove({ id }).pipe(finalize(() => this.loader.stop()));
            }
          }

          return EMPTY;
        }),
        tap(() => this.removeImage(id)),
        tap(() => {
          if (!this.images.length) {
            this.close();
          }
        })
      )
      .subscribe();
  }

  private removeImage(id: number) {
    this.isChanged = true;
    const idx = this.images.findIndex(item => item.id === id);
    this.swiper.removeSlide(idx);
    this.images.splice(idx, 1);
    this.swiper.update();
    this.cdr.markForCheck();
  }

  private listenBackdropClick() {
    this.dialogRef
      .backdropClick()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.close())
      )
      .subscribe();
  }

  private openSuccessMessage(successMessage: string) {
    this.snackBarNotificationService.openSuccessMessage(successMessage);
  }
}
