import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewChecked,
  Renderer2,
  HostListener,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth.service';
import { StoryDialogConfirmationComponent } from 'src/app/shared/story-dialog-confirmation/story-dialog-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { AddIconsThroughMaterialService } from 'src/app/core/add-icons-through-material.service';
import { SellStoryService } from 'src/app/core/sell-story.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { LoadingService } from 'src/app/loading-overlay/loading.service';
import { SwiperModalComponent } from '../swiper-modal/swiper-modal.component';
import { StoryModalFormEditComponent } from 'src/app/admin/admin-shared/story-modal-form-edit/story-modal-form-edit.component';
import { SwiperModalData } from '../swiper-modal/swiper-modal-data';
import { Image } from '../../interfaces/image';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { StoryPublic } from 'src/app/interfaces/story-public';
import { AdminDataService } from 'src/app/core/admin-data.service';
import { UserRole } from '../../enums/user-role.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { shareOnSocialMedia } from 'src/app/shared/share-story-utils';
import { UserInfo } from 'src/app/interfaces/user-info';
import { ObjectFit } from '../../enums/object-fit.enum';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';
import { isPlatformBrowser } from '@angular/common';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { RouterUtilsService } from 'src/app/core/router-utils.service';
import { SidebarService } from 'src/app/core/sidebar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cl-modal-view-story',
  templateUrl: './modal-view-story.component.html',
  styleUrls: ['./modal-view-story.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalViewStoryComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  storyData: any;
  confirmationRequest: string;
  isLoading: boolean;
  isChronicleAdmin: boolean;
  plotId: string;
  isOutsideButton = true;
  isShareButton = false;
  hideShare = true;
  responsive$: boolean;
  @ViewChild('buttonWrapper') buttonWrapper!: ElementRef<any>;
  @ViewChild('editIcon') editIcon!: ElementRef<any>;
  @ViewChild('shareButton', { read: ElementRef, static: false }) shareButton!: ElementRef<any>;
  @ViewChild('listShareButton') listShareButton!: ElementRef<any>;
  @ViewChild('contentStory') contentStory!: ElementRef<any>;
  @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;

  detailStory: StoryPublic;
  show = false;
  destroy$ = new Subject<void>();
  imageFit = ObjectFit.Contain;
  chronicleLinks: string[] = [];

  needToCheck = false;

  get stories() {
    const component = document.createElement('div');
    const story = this.detailStory ? this.detailStory?.text : this.dialogData?.storyOfficial?.text;
    component.innerHTML = story;
    const links = component.querySelectorAll('a');
    links.forEach(link => {
      if (link.href.includes('plots')) {
        const linkTemp = link.href;
        this.chronicleLinks.push(linkTemp);
        link.removeAttribute('href');
        link.removeAttribute('target');
        link.classList.add('chronicle-link');
        link.style.cursor = 'pointer';
        link.style.color = 'blue';
      }
    });
    return component.outerHTML;
  }
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      story: any;
      public: any;
      status: any;
      storiesId: any;
      interment: any;
      storyOfficial: any;
      plotDetails: any;
      admin: any;
      adminStory: any;
      fromCta?: boolean;
      fromRequestTable?: boolean;
      detailStory?: any;
      fromPreviewLifeChronicle?: boolean;
    },
    private readonly dialogRef: MatDialogRef<ModalViewStoryComponent>,
    private authService: AuthService,
    private dialog: MatDialog,
    private addIconsThroughMaterialService: AddIconsThroughMaterialService,
    private sellStory: SellStoryService,
    private ref: ChangeDetectorRef,
    private snackBarNotificationService: SnackbarNotificationService,
    private loadingService: LoadingService,
    private adminDataService: AdminDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private mobileResponsiveService: MobileResponsiveService,
    private amplitudeService: AmplitudeService,
    private elementRef: ElementRef,
    private routerUtilsService: RouterUtilsService,
    private sidebarService: SidebarService,
    @Inject(PLATFORM_ID)
    private platformId: any
  ) {
    this.addIconsThroughMaterialService.add([{ name: 'custom_place', src: '/assets/images/place_icon.svg' }]);
    this.adminDataService
      .getUserInfo()
      .pipe(
        tap((user: UserInfo) => {
          this.isChronicleAdmin =
            user?.role === UserRole.CHRONICLE_ADMIN ||
            user?.role === UserRole.CUSTOMER_ADMIN ||
            user?.role === UserRole.CUSTOMER_MANAGER ||
            user?.role === UserRole.CUSTOMER_ORGANIZATION;
          this.ref.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.addIconsThroughMaterialService.add([
      { name: 'facebook', src: '/assets/images/facebook.svg' },
      { name: 'linkedin', src: '/assets/images/linkedin.svg' },
      { name: 'twitter', src: '/assets/images/twitter.svg' },
      { name: 'share', src: '/assets/images/share.svg' },
      { name: 'custom_shared_plot_details_close', src: '/assets/images/close_icon_white.svg' },
    ]);

    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.shareButton?.nativeElement &&
        this.shareButton?.nativeElement.length &&
        !this.shareButton?.nativeElement.contains(e.target)
      ) {
        this.isOutsideButton = false;
        this.isShareButton = true;
      }
    });
    this.isShareButton = false;
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (this.contentStory.nativeElement.scrollTop > 0) {
      this.hideShare = false;
      return;
    }
    this.hideShare = true;
  }

  ngOnInit(): void {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.ref.detectChanges();
          if (!isPlatformBrowser(this.platformId)) {
            return;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    if (this.dialogData.status === 'preview') {
      this.detailStory = this.dialogData.detailStory;
    }
    if (!this.dialogData.story) {
      return;
    }

    this.storyData = this.dialogData.story;
    const storyId = this.dialogData.storiesId ? this.dialogData.storiesId : this.storyData.id;
    this.isLoading = true;
    this.sellStory
      .getStoryPublicById(storyId)
      .pipe(
        tap<StoryPublic>(data => {
          this.detailStory = data;
        }),
        finalize(() => {
          this.isLoading = false;
          this.needToCheck = true;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.dialogRef.afterClosed().subscribe(a => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { stories: null },
        queryParamsHandling: 'merge',
      });
    });
  }

  ngAfterViewInit() {
    this.transformChronicleLink();
  }

  ngAfterViewChecked() {
    if (this.needToCheck) {
      this.transformChronicleLink();
      this.needToCheck = false;
    }
  }

  async onClick(url: string) {
    const arrayUrl = new URL(url).pathname.split('/');
    const plotId = decodeURI(arrayUrl[arrayUrl.length - 1]);
    const cemeteryName = arrayUrl[arrayUrl.length - 3];

    const path = this.routerUtilsService.getPath(cemeteryName, 'plots', plotId);
    await this.router.navigate(path);
    this.sidebarService.closeMovePlot(true);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOpenStoryDialogConfirmation(val: string) {
    this.dialog
      .open(StoryDialogConfirmationComponent, {
        panelClass: 'story-success-dialog-component',
        width: '436px',
        disableClose: true,
        autoFocus: false,
        data: { data: val },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data?.data === 'reject') {
          this.amplitudeService.track('admin - life chronicle detail for approval - reject button clicked');
          return this.rejectStory(data.reason);
        } else if (data?.data === 'approve') {
          this.amplitudeService.track('admin - life chronicle detail for approval - approve button clicked');
          return this.approveStory();
        }
      });
  }

  onOpenFormEditStory() {
    this.amplitudeService.track('admin - life chronicle detail for approval - edit button clicked', this.detailStory);
    this.dialog
      .open(StoryModalFormEditComponent, {
        panelClass: 'story-form-edit-component',
        width: '465px',
        height: '82%',
        disableClose: true,
        autoFocus: false,
        data: { data: this.detailStory },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.dialogRef.close();
        }
      });
  }

  rejectStory(reason) {
    this.isLoading = true;
    if (this.dialogData.fromRequestTable) {
      this.loadingService.run();
      this.sellStory
        .rejectStoryRequestTable(this.storyData.id, reason)
        .pipe(
          catchError(error => {
            this.openErrorMessage(error.error.message);
            return EMPTY;
          }),
          tap(data => {
            this.openSuccessMessage('Story reject successfully!');
            this.onCancel(true);
          }),
          finalize(() => {
            this.loadingService.stop();
            this.isLoading = false;
            this.ref.detectChanges();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.loadingService.run();
      this.sellStory
        .rejectStory(this.storyData.id, reason)
        .pipe(
          catchError(error => {
            this.openErrorMessage(error.error.message);
            return EMPTY;
          }),
          tap(data => {
            this.openSuccessMessage('Story reject successfully!');
            this.onCancel(true);
          }),
          finalize(() => {
            this.loadingService.stop();
            this.isLoading = false;
            this.ref.detectChanges();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  approveStory() {
    this.isLoading = true;
    if (this.dialogData.fromRequestTable) {
      this.loadingService.run();

      this.sellStory
        .approveStoryRequestTable(this.storyData.id)
        .pipe(
          catchError(error => {
            this.openErrorMessage(error.error.message);
            return EMPTY;
          }),
          tap(data => {
            this.openSuccessMessage('Story approve successfully!');
            this.onCancel(true);
          }),
          finalize(() => {
            this.loadingService.stop();
            this.isLoading = false;
            this.ref.detectChanges();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
      return;
    } else {
      this.loadingService.run();

      this.sellStory
        .approveStory(this.storyData.id)
        .pipe(
          catchError(error => {
            this.openErrorMessage(error.error.message);
            return EMPTY;
          }),
          tap(data => {
            this.openSuccessMessage('Story approve successfully!');
            this.onCancel(true);
          }),
          finalize(() => {
            this.loadingService.stop();
            this.isLoading = false;
            this.ref.detectChanges();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  onCancel(data) {
    this.dialogRef.close(data);
  }

  onClose() {
    this.dialogRef.close();
  }

  toggleShare() {
    this.isOutsideButton = false;
    this.isShareButton = true;
  }

  openPreview(index: number) {
    this.dialog.open<SwiperModalComponent, SwiperModalData, Image[]>(SwiperModalComponent, {
      panelClass: 'slider-container',
      data: {
        entityTitle: '',
        images: this.detailStory.images,
        startFrom: index,
        download: true,
        share: true,
      },
    });
  }

  shareStory(socialMedia: string) {
    shareOnSocialMedia(socialMedia, this.detailStory, this.dialogData.storyOfficial, this.dialogData.plotDetails);
    switch (socialMedia) {
      case 'facebook':
        if (this.dialogData.storyOfficial) {
          this.amplitudeService.track('Official story share facebook clicked');
        }
        if (this.dialogData.story) {
          this.amplitudeService.track('Public life chronicle share facebook clicked');
        }
        break;
      case 'twitter':
        if (this.dialogData.storyOfficial) {
          this.amplitudeService.track('Official story share twitter clicked');
        }
        if (this.dialogData.story) {
          this.amplitudeService.track('Public life chronicle share twitter clicked');
        }
        break;
    }
  }

  containImage(storyData: any, dialogData: any) {
    let images: any[];

    if (storyData) {
      images = storyData?.images;
    } else {
      images = dialogData?.storyOfficial?.images;
    }

    if (!images || images.length === 0) {
      return false;
    }

    return true;
  }

  goToDetailPlot() {
    const plotId = this.dialogData.story?.interment_record?.plot?.plot_id;
    const currentUrl = this.router.url;
    this.router.navigate([currentUrl, 'plots', plotId]).then(() => {
      this.dialogRef.close();
    });
  }

  private openSuccessMessage(successMessage: string) {
    this.snackBarNotificationService.openSuccessMessage(successMessage);
  }

  private openErrorMessage(errorMessage: string) {
    this.snackBarNotificationService.openErrorMessage(errorMessage);
  }

  private transformChronicleLink() {
    const elements = this.elementRef.nativeElement.querySelectorAll('.chronicle-link');
    elements.forEach((element, i) => {
      element.addEventListener(
        'click',
        event => {
          event.preventDefault();
          this.onClick(this.chronicleLinks[i]);
        },
        false
      );
    });
  }
}
