import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { NavigateBackService } from 'src/app/core/navigate-back.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PlotDetails } from 'src/app/interfaces/plot-details';
import { PlotsService } from 'src/app/core/plots.service';
import { SnackbarNotificationService } from 'src/app/core/snackbar-notification.service';
import { catchError, concatMap, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { LoadingService } from 'src/app/loading-overlay/loading.service';
import { Organization } from 'src/app/interfaces/organization';
import { OrganizationService } from 'src/app/admin/chronicle-admin/organization.service';
import { AmplitudeService } from 'src/app/core/amplitude.service';
import { RequestManagementCemeteryService } from 'src/app/admin/admin-shared/request-management-cemetery.service';

@Component({
  selector: 'cl-form-purchase-pre-need',
  templateUrl: './form-purchase-pre-need.component.html',
  styleUrls: ['./form-purchase-pre-need.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPurchasePreNeedComponent implements OnInit, OnDestroy {
  @ViewChild('dialogSuccessPurchase') dialogSuccessPurchaseTpl: TemplateRef<any>;

  destroy$ = new Subject<void>();

  loaders$ = new BehaviorSubject({});

  sourceForm: any;

  plotDetails: PlotDetails;

  allSectionValid = false;

  isPreview = false;

  formValue: any;

  dataPreview: any;

  formTitle: string;

  formType: string;

  resultPlotPurchase: any;

  isLoadingPdf = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private navigateBackService: NavigateBackService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private plotService: PlotsService,
    private snackbarService: SnackbarNotificationService,
    private loadingService: LoadingService,
    private organizationService: OrganizationService,
    private amplitudeService: AmplitudeService,
    private requestManagementCemeteryService: RequestManagementCemeteryService
  ) {
    this.dataPreview = this.organizationService.getFormsFromLocalStorage();
    this.formType = this.activatedRoute.snapshot.params.type || this.activatedRoute.snapshot.queryParams.type;
  }

  ngOnInit(): void {
    this.plotDetails = this.activatedRoute.snapshot.data?.plotDetails;
    this.isPreview = this.activatedRoute.snapshot.queryParams.mode === 'preview';
    this.formTitle =
      this.activatedRoute.snapshot.queryParams.type === 'custom'
        ? this.activatedRoute.snapshot.queryParams?.title || 'Custom'
        : `${this.formType} Plot Purchase`;
    if ((this.sourceForm === undefined || this.sourceForm === null) && this.isPreview && this.dataPreview) {
      this.sourceForm = this.dataPreview;
    } else {
      this.sourceForm = this.activatedRoute.snapshot.data?.purchasePlotData.find(value => value.display_on === 'plot_purchase');
    }
  }

  sectionValid(event) {
    this.allSectionValid = event.isValid;
    this.formValue = event.value;
    this.cdr.detectChanges();
  }

  submit(): void {
    if (this.isPreview) {
      return;
    }
    const payload = {
      plot_id: this.plotDetails.id,
      sub_type: this.formType,
      custom_form_value: this.formValue.sections,
    };
    this.amplitudeService.track(`5a. Plot Purchase - Button "Submit" Clicked`, {
      ...payload,
    });

    this.plotService
      .createPlotPurchase(this.plotDetails.id, payload)
      // this.plotService
      //   .createPlotPurchaseToRequest(this.plotDetails.id, payload)
      .pipe(
        this.loadingService.setLoaders('createPlotPurchase', this.loaders$),
        catchError(error => {
          this.snackbarService.openErrorMessage(error.error.message);
          return EMPTY;
        }),
        filter(result => {
          this.resultPlotPurchase = result;
          return result.id;
        }),
        concatMap(() => {
          return this.dialog
            .open(this.dialogSuccessPurchaseTpl, {
              width: '480px',
              height: '392px',
              disableClose: true,
            })
            .afterClosed()
            .pipe(
              switchMap(() => {
                this.navigateBackService.goBack();
                return this.router.events.pipe(
                  filter(event => event instanceof NavigationStart),
                  this.loadingService.setLoaders('createPlotPurchase', this.loaders$)
                );
              })
            );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  cancel() {
    this.navigateBackService.goBack();
  }

  viewPdf() {
    this.isLoadingPdf = true;
    this.cdr.detectChanges();
    const requestId = this.resultPlotPurchase.id;
    this.requestManagementCemeteryService
      .getSubmissionPdfByPlotPurchaseid(requestId)
      .pipe(
        tap((res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'pdf';
          link.click();
        }),
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoadingPdf = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
