<div class="content">
  <div class="left">
    <ng-container *ngIf="!isEditForm">
      <a (click)="backToPreviousPage()" class="arrow">
        <span class="material-icons"> west </span>
      </a>
      <a href="/">
        <img class="logo" src="../../../assets/images/full_logo.svg" alt=""/>
      </a>
        <div class="header" *ngIf="!responsive$">
          <div class="organization" *ngIf="showHeader">Organisation configuration</div>
          <div class="username">{{username}}</div>
        </div>
    </ng-container>
    <ng-container *ngIf="isEditForm">
      <a href="/">
        <img src="../../../assets/images/chronicle_logo.svg" alt="" class="logo2" *ngIf="!responsive$" />
        <img src="../../../assets/images/full_logo.svg" alt="" class="logo" *ngIf="responsive$" />
      </a>
    </ng-container>
  </div>

  <div [ngClass]="responsive$ ? 'right-responsive' : 'right'">
    <ng-content></ng-content>
  </div>
</div> 