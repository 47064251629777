import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MobileResponsiveService } from 'src/app/core/mobile-responsive.service';


@Component({
  selector: 'cl-toolbar-organization',
  templateUrl: './toolbar-organization.component.html',
  styleUrls: ['./toolbar-organization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarOrganizationComponent implements OnInit, OnDestroy {
  @Input() username: string;
  @Input() isEditForm: boolean;
  @Input() showHeader = true;
  @Output() backToPrevious = new EventEmitter();

  destroy$: Subject<void> = new Subject<void>();
  responsive$ = false;

  constructor(private ref: ChangeDetectorRef, private mobileResponsiveService: MobileResponsiveService) { }

  ngOnInit() {
    this.mobileResponsiveService.isResponsive$
      .pipe(
        tap(res => {
          this.responsive$ = res;
          this.ref.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  backToPreviousPage() {
    this.backToPrevious.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
