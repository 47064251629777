import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTooltip } from '@angular/material/tooltip';
import { Subject, of } from 'rxjs';
import { delay, filter, takeUntil, tap } from 'rxjs/operators';


export interface CertificateVariableData {
  tabs: CertificateVariableTabsData[];
}

export type CertificateVariableTabsData = {
  [T: string]: CertificateVariableTabsValue[];
};

export interface CertificateVariableTabsValue {
  key: string;
  name: string;
}

@Component({
  selector: 'cl-list-certificate-variables-modal',
  templateUrl: './list-certificate-variables-modal.component.html',
  styleUrls: ['./list-certificate-variables-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListCertificateVariablesModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  tabLabel: string[] = [];

  displayedColumns: string[] = ['key', 'name'];

  dataSource: CertificateVariableTabsValue[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private certificateVariableData: CertificateVariableData,
    private dialogRef: MatDialogRef<ListCertificateVariablesModalComponent>,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.tabLabel = this.getTabLabel();

    this.dataSource = this.certificateVariableData.tabs[0][this.tabLabel[0]];

    this.dialogRef.keydownEvents().pipe(
      tap((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.dialogRef.close();
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  getTabLabel(): string[] {
    const tabs = this.certificateVariableData.tabs[0];
    return Object.keys(tabs).map(key => key.replace(/_/g, ' '));
  }

  onTabChange(event: MatTabChangeEvent): void {
    const currentTabIndex = event.index;
    const currentTabKey = Object.keys(this.certificateVariableData.tabs[0])[currentTabIndex];
    this.dataSource = this.certificateVariableData.tabs[0][currentTabKey];
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(`{${text}}`);
  }

  onCopyToClipboard(event: boolean, tooltip: MatTooltip): void {
    of(event).pipe(
      filter((value: boolean) => !!value),
      tap(() => {
        tooltip.disabled = false;
        tooltip.show();
      }),
      delay(1000),
      tap(() => tooltip.hide()),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
