import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { CemeteriesObCache, CemeteryInfo, CemeteryInfoResult, CemeteryLockInfo, CemeteryObCache } from '../interfaces/cemetery-info';
import { HttpUtilsService } from './http-utils.service';
import { CEMETERIES_LIST_DEFAULT_SORT_PARAM } from '../admin/chronicle-admin-organization-shared/cemeteries-list-sort-params.enum';
import { CemeteryCreateEditInfo } from '../interfaces/cemetery-create-edit-info';
import { shareReplay, tap } from 'rxjs/operators';
import { ValidateResponseService } from '../server-response-validation/validate-response.service';
import { ValidatorType } from '../server-response-validation/validator-type.enum';
import { RegionalSettingsService } from './regional-settings.service';
import { StorageType } from '../enums/storage-type.enum';
import { StorageKey } from '../enums/storage-key.enum';
import { TokenStorageService } from './token-storage.service';
import { Token } from '../enums/token.enum';
import { StoryPublicResponse } from '../interfaces/story-public';
@Injectable({
  providedIn: 'root',
})
export class CemeteryService {
  private readonly cemeteryLockStatus = new ReplaySubject<boolean>(1);
  private readonly cemeteryUniqueName = new BehaviorSubject<string>(null);
  private cemeteriesCache: { [key: string]: Observable<CemeteryInfoResult> } = {}; // Add cache property
  sourceCemeteries: Observable<CemeteryInfoResult>;
  sourceListCemetery: CemeteryObCache[] = [];
  sourceMyCemeteries: CemeteriesObCache[] = [];
  currentCemeteryByOrg$: BehaviorSubject<CemeteryInfoResult> = new BehaviorSubject<CemeteryInfoResult>(null);

  get cemeteryUniqueName$() {
    return this.cemeteryUniqueName.asObservable();
  }

  get isLock() {
    return this.cemeteryLockStatus.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private regionalSettingsService: RegionalSettingsService,
    private readonly httpUtils: HttpUtilsService,
    private readonly validateResponseService: ValidateResponseService,
    private tokenStorage: TokenStorageService
  ) {}

  updateCemeteryUniqueName(cemeteryUniqueName: string) {
    this.cemeteryUniqueName.next(cemeteryUniqueName);
  }

  setSourceCemeteries(newData: Observable<CemeteryInfoResult>) {
    this.sourceCemeteries = newData;
  }

  cleanSourceCemeteries() {
    this.sourceCemeteries = undefined;
  }

  cleanSourceListCemetery() {
    this.sourceListCemetery = [];
  }

  cleanSourceMyCemeteries() {
    this.sourceMyCemeteries = [];
  }

  findSourceListCemeteryByKey(key: string) {
    const res = this.sourceListCemetery.find(a => a.key === key);
    if (!res) {
      return undefined;
    }
    return res.data;
  }

  insertNewSourceListCemeteryByKey(key: string, data: Observable<CemeteryInfo>) {
    this.sourceListCemetery.push({
      key,
      data,
    });
  }

  findSourceMyCemeteriesByKey(key: string) {
    const res = this.sourceMyCemeteries.find(a => a.key === key);
    if (!res) {
      return undefined;
    }
    return res.data;
  }

  insertNewSourceMyCemeteriesByKey(key: string, data: Observable<CemeteryInfoResult>) {
    this.sourceMyCemeteries.push({
      key,
      data,
    });
  }

  cleanAllCache() {
    this.cleanSourceCemeteries();
    this.cleanSourceListCemetery();
    this.cleanSourceMyCemeteries();
    this.cemeteriesCache = {}; // Clean cache
  }

  private static get sortCemeteriesStorage() {
    return window[StorageType.local];
  }

  setDefaultSortCemeteries() {
    if (!CemeteryService.sortCemeteriesStorage.getItem(StorageKey.cemeteriesSortBy)) {
      CemeteryService.sortCemeteriesStorage.setItem(StorageKey.cemeteriesSortBy, CEMETERIES_LIST_DEFAULT_SORT_PARAM.toString());
    }
  }

  getSortCemeteries() {
    const sort = CemeteryService.sortCemeteriesStorage.getItem(StorageKey.cemeteriesSortBy);

    if (!sort) {
      return CEMETERIES_LIST_DEFAULT_SORT_PARAM;
    }

    return parseInt(sort, 0);
  }

  setSortCemeteries(sort) {
    CemeteryService.sortCemeteriesStorage.setItem(StorageKey.cemeteriesSortBy, sort.toString());
  }

  getCemeteries(page?: number, limit?: number, org?: string): Observable<CemeteryInfoResult> {
    const accessToken = this.tokenStorage.get(Token.access);
    const url = accessToken ? this.httpUtils.getResourceUrl(['ms', 'cemetery-admin']) : this.httpUtils.getResourceUrl(['ms', 'cemetery']);

    let paramsObj;
    if (org && !accessToken) {
      paramsObj = {
        page: page ? page.toString() : '1',
        limit: limit ? limit.toString() : '0',
        org: decodeURIComponent(org),
      };
    } else {
      paramsObj = {
        page: page ? page.toString() : '1',
        limit: limit ? limit.toString() : '0',
      };
    }

    const params = new HttpParams({ fromObject: paramsObj });
    const key = JSON.stringify(paramsObj);

    // Check cache first
    if (this.cemeteriesCache[key]) {
      return this.cemeteriesCache[key];
    }

    const resOb = this.httpClient.get<CemeteryInfoResult>(url.slice(0, -1), { params }).pipe(
      shareReplay(1), // Share the result with multiple subscribers
      tap(data => {
        // Save the result in the cache
        this.cemeteriesCache[key] = of(data);
      })
    );

    // Save the observable in the cache
    this.cemeteriesCache[key] = resOb;
    return resOb;
  }

  getCemeteriesBackUp(page?: number, limit?: number, org?: string) {
    let url: string;

    if (this.sourceCemeteries) {
      return this.sourceCemeteries;
    }

    const accessToken = this.tokenStorage.get(Token.access);
    if (accessToken) {
      url = this.httpUtils.getResourceUrl(['ms', 'cemetery-admin']);
    } else {
      url = this.httpUtils.getResourceUrl(['ms', 'cemetery']);
    }
    let paramsObj;
    if (org && !accessToken) {
      paramsObj = {
        page: page ? page.toString() : '1',
        limit: limit ? limit.toString() : '0',
        org: decodeURIComponent(org),
      };
    } else {
      paramsObj = {
        page: page ? page.toString() : '1',
        limit: limit ? limit.toString() : '0',
      };
    }
    const params = new HttpParams({ fromObject: paramsObj });
    const resOb = this.httpClient.get<CemeteryInfoResult>(url.slice(0, -1), { params });
    this.setSourceCemeteries(resOb);
    return resOb;
  }

  getCemeteriesNew(page?: number, limit?: number, org?: string, sortParam?: number) {
    let url: string;

    if (!sortParam) {
      sortParam = CEMETERIES_LIST_DEFAULT_SORT_PARAM;
    }

    const accessToken = this.tokenStorage.get(Token.access);
    if (accessToken) {
      url = this.httpUtils.getResourceUrl(['ms', 'cemetery-admin']);
    } else {
      url = this.httpUtils.getResourceUrl(['ms', 'cemetery']);
    }
    let paramsObj;
    if (org && !accessToken) {
      paramsObj = {
        page: page ? page.toString() : '1',
        limit: limit ? limit.toString() : '0',
        org: decodeURIComponent(org),
        order_by: sortParam.toString(),
      };
    } else {
      paramsObj = {
        page: page ? page.toString() : '1',
        limit: limit ? limit.toString() : '0',
      };
    }

    const params = new HttpParams({ fromObject: paramsObj });
    const resOb = this.httpClient.get<CemeteryInfoResult>(url.slice(0, -1), { params }).pipe(shareReplay(1));
    return resOb;
  }

  getMyCemeteries(sortParam?: number, role: number = -1) {
    const url = this.httpUtils.getResourceUrl(['cemeteries', 'my']);

    if (!sortParam) {
      sortParam = CEMETERIES_LIST_DEFAULT_SORT_PARAM;
    }

    let params = new HttpParams().append('order_by', sortParam.toString());

    if (role >= 0) {
      params = params.append('role', role.toString());
    }

    return this.httpClient
      .get<CemeteryInfo[]>(url, { params })
      .pipe(
        tap(cemeteriesInfo =>
          cemeteriesInfo.forEach(cemeteryInfo => this.validateResponseService.validate(ValidatorType.CemeteryInfo, cemeteryInfo))
        )
      );
  }

  getMyMyCemeteriesNew(page?: number, limit?: number, sortParam?: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemeteries', 'my']);

    if (!sortParam) {
      sortParam = CEMETERIES_LIST_DEFAULT_SORT_PARAM;
    }

    const paramsObj = {
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
      order_by: sortParam.toString(),
    };

    const key = url.concat(JSON.stringify(paramsObj));
    const find = this.findSourceMyCemeteriesByKey(key);
    if (find) {
      return find;
    }

    const params = new HttpParams({ fromObject: paramsObj });
    const res = this.httpClient.get<CemeteryInfoResult>(url.slice(0, -1), { params }).pipe(shareReplay(1));
    this.insertNewSourceMyCemeteriesByKey(key, res);
    return res;
  }

  getCemetery(cemeteryIdentifier: string | number) {
    let url: string;

    const accessToken = this.tokenStorage.get(Token.access);
    if (accessToken) {
      url = this.httpUtils.getResourceUrl(['ms', 'cemetery-admin', cemeteryIdentifier]);
    } else {
      url = this.httpUtils.getResourceUrl(['ms', 'cemetery', cemeteryIdentifier]);
    }

    const res = this.httpClient.get<CemeteryInfo>(url.slice(0, -1)).pipe(
      tap(cemeteryInfo => this.regionalSettingsService.getRegionalSettingsForCemetery(cemeteryInfo.unique_name).subscribe()),
      tap(cemeteryInfo => this.validateResponseService.validate(ValidatorType.CemeteryInfo, cemeteryInfo)),
      shareReplay(1)
    );

    return res;
  }

  getCemeteriesByOrganization(orgId: number, keyword: string) {
    const accessToken = this.tokenStorage.get(Token.access);
    const url = this.httpUtils.getResourceUrl(['ms', 'cemeteries-by-org']);

    let paramsObj: { page: string; limit: string; org: string; keyword?: string } = {
      page: '1',
      limit: '0',
      org: orgId.toString(),
    };

    if (keyword) {
      paramsObj = { ...paramsObj, keyword };
    }

    const params = new HttpParams({ fromObject: paramsObj });

    return this.httpClient.get<CemeteryInfoResult>(url, { headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` }), params });
  }

  getCemeteryWithToken(cemeteryIdentifier: string | number, token: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemetery-admin', cemeteryIdentifier]);

    return this.httpClient
      .get<CemeteryInfo>(url.slice(0, -1), {
        headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
      })
      .pipe(tap(cemeteryInfo => this.validateResponseService.validate(ValidatorType.CemeteryInfo, cemeteryInfo)));
  }

  searchCemetery(search: string, limit: number, org?: string) {
    const url = this.httpUtils.getResourceUrl(['search', 'cemeteries']);
    let paramsObj;
    if (org) {
      paramsObj = {
        search,
        limit,
        org,
        show_archived: 'false',
      };
    } else {
      paramsObj = {
        search,
        limit,
        show_archived: 'false',
      };
    }
    const params = new HttpParams({ fromObject: paramsObj });

    return this.httpClient
      .get<CemeteryInfo[]>(url, { params })
      .pipe(tap(cemeteries => cemeteries.forEach(cemetery => this.validateResponseService.validate(ValidatorType.CemeteryInfo, cemetery))));
  }

  create(cemeteryData: CemeteryCreateEditInfo) {
    const url = this.httpUtils.getResourceUrl(['cemetery']);

    return this.httpClient.post<CemeteryInfo>(url, cemeteryData);
  }

  update(id: number, cemeteryData: CemeteryCreateEditInfo) {
    const url = this.httpUtils.getResourceUrl(['cemetery', id]);

    return this.httpClient.put<CemeteryInfo>(url, cemeteryData);
  }

  restore(id: number) {
    const url = this.httpUtils.getResourceUrl(['cemetery', id, 'restore']);

    return this.httpClient.put(url, {});
  }

  archiveCemetery(cemeteryId: number) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryId, 'archive']);

    return this.httpClient.put(url, {}) as Observable<any>;
  }

  concealDemoCemetery(userId: number) {
    const url = this.httpUtils.getResourceUrl(['access_demo', userId]);

    return this.httpClient.delete(url);
  }

  getCemeteryUnderSameOrg(cemeteryUniqueName: string) {
    const url = this.httpUtils.getResourceUrl(['cemetery', cemeteryUniqueName, 'organization']);
    return this.httpClient.get<CemeteryInfo[]>(url);
  }

  getCemeteriesByViewPort(boundary: string, page?: number, limit?: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemeteries-by-viewport']);

    const paramsObj = {
      bounds: boundary,
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
    };

    const params = new HttpParams({ fromObject: paramsObj });

    return this.httpClient.get<CemeteryInfoResult>(url.slice(0, -1), { params }).pipe(shareReplay(1));
  }

  updateLockStatus(cemeteryUniqueName: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemetery', cemeteryUniqueName, 'locked-status']);

    return this.httpClient.put<CemeteryLockInfo>(url.slice(0, -1), null);
  }

  getLockStatus(cemeteryUniqueName: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemetery', cemeteryUniqueName, 'locked-status']);

    return this.httpClient.get<CemeteryLockInfo>(url.slice(0, -1));
  }

  renewLockStatus(newStatus: boolean) {
    this.cemeteryLockStatus.next(newStatus);
  }

  getStoryTopList(cemeteryUniqueName: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'story', 'top-list', cemeteryUniqueName]);
    const paramsObj = {
      page: '1',
      limit: '5',
    };
    const params = new HttpParams({ fromObject: paramsObj });
    return this.httpClient.get<StoryPublicResponse>(url.slice(0, -1), { params });
  }

  deleteCache(cemeteryUniqueName: string) {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemetery', cemeteryUniqueName, 'clear-cache']);
    return this.httpClient.put<any>(url.slice(0, -1), null);
  }

  getDemoCemetery(): Observable<CemeteryInfoResult> {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemetery-demo']);
    const params = { page: '1', limit: '0' };
    return this.httpClient.get<CemeteryInfoResult>(url.slice(0, -1), { params: new HttpParams({ fromObject: params }) });
  }

  getCemeteryPublic(page?: number, limit?: number, org?: string, sortParam?: number) {
    const paramsObj = {
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
      org: decodeURIComponent(org),
      order_by: sortParam.toString(),
    };

    const url = this.httpUtils.getResourceUrl(['ms', 'cemetery']);
    const params = new HttpParams({ fromObject: paramsObj });
    return this.httpClient.get<CemeteryInfoResult>(url.slice(0, -1), { params });
  }

  getPartnerCemeteries(page?: number, limit?: number) {
    const url = this.httpUtils.getResourceUrl(['ms', 'cemeteries-partner']);

    const paramsObj = {
      page: page ? page.toString() : '1',
      limit: limit ? limit.toString() : '0',
    };

    const params = new HttpParams({ fromObject: paramsObj });
    const resOb = this.httpClient.get<CemeteryInfoResult>(url, { params }).pipe(shareReplay(1));

    return resOb;
  }
}
